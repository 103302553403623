import React, { useState } from "react";
import "./registrationStepTwo.scss";
import { Link, useNavigate } from "react-router-dom";
import { DatePicker, message } from "antd";
import moment from "moment";
import axios from "axios";
import { ApiBaseUrl } from "../../BaseUrl";
import { toast } from "react-toastify";
import { useDispatch } from 'react-redux';
import {

  verifyOtpRequestSignup,
} from "../../redux/slices/auth/RegisterSlice";
const RegisterationStepTwo = () => {
  const [formData, setFormData] = useState({
    full_name: "",
    email: "",
    dob: null,
    gender: "",
    otp: "",
  });
  const [otpSent, setOtpSent] = useState(false); // State to track OTP sent status
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user_mobile = localStorage.getItem("user_mobile");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDateChange = (date) => {
    if (date) {
      setFormData((prevData) => ({
        ...prevData,
        dob: date.format("YYYY-MM-DD"), 
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        dob: null, 
      }));
    }
  };
  
  // Function to send OTP
  const sendOtp = () => {
    if (formData.email) {
      axios
        .post(`${ApiBaseUrl}/email-otp-send`, { email: formData.email })
        .then((response) => {
          message.success("OTP sent successfully to your email!");
          setOtpSent(true); // Mark OTP as sent
        })
        .catch((error) => {
          console.error("Error sending OTP:", error);
          message.error("Failed to send OTP. Please try again.");
        });
    } else {
      message.warning("Please enter a valid email address.");
    }
  };

  // Function to handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    const submissionData = {
      email: formData.email,
      mobile: user_mobile, 
      otp: formData.otp,
      name: formData.full_name,
      dob: formData.dob,
      gender: formData.gender,
    };
    axios
      .post(`${ApiBaseUrl}/email-otp-verify`, submissionData)
      .then((result) => {
      if(result.data.error){
        toast.warning(result.data.error.message)
      }else{
      localStorage.setItem("isUserVerified", true);
      localStorage.setItem("isUserLoggedIn", true);
      localStorage.setItem("ACCESS_TOKEN", result.data.Jwt.access);
      localStorage.setItem("PARENT_ID", result.data.client.id);
      localStorage.setItem("ROLE", result.data.client.role);
      localStorage.setItem("PROFILE_PIC", result.data.client.profile_pic);
      localStorage.setItem("USER_NAME", result.data.client.name);
      localStorage.setItem("USER_EMAIL", result.data.client.email);
      toast.success("User registered successfully")
      setTimeout(() => {
        navigate("/parent-profile");
      }, 2000);
      }
      })
      .catch((error) => {
        console.error("Error:", error.response.data);
        message.error("Sign up failed. Please try again.");
      });
  };

  return (
    <div className="registration-step-two-container-wrapper flex-center">
      <div className="form-container-wrapper flex-center">
        <div className="login-heading-container">
          <h1 className="header-one">Sign Up</h1>
          <p>Create your profile and start hosting your pet with our trusted hosts.</p>
        </div>
        <div className="login-form-container flex-center">
          <input
            type="text"
            name="full_name"
            placeholder="Full Name"
            value={formData.full_name}
            onChange={handleChange}
          />
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
          <button
            className="send-otp-button"
            onClick={sendOtp}
            disabled={!formData.email || otpSent} // Disable if email is empty or OTP is sent
          >
            Send OTP
          </button>
          <input
            type="text"
            name="otp"
            placeholder="Enter OTP"
            value={formData.otp}
            onChange={handleChange}
            disabled={!otpSent} // Enable only if OTP is sent
          />
          <DatePicker
            key={formData.dob || "default"}
            className="dob-picker"
            placeholder="DD/MM/YYYY"
            format="DD/MM/YYYY"
            value={formData.dob ? moment(formData.dob, "YYYY-MM-DD") : null}
            onChange={handleDateChange}
          />
          <div className="gender-input-wrapper">
            <input
              type="radio"
              id="male"
              name="gender"
              value="MALE"
              checked={formData.gender === "MALE"}
              onChange={handleChange}
            />
            <label htmlFor="male">Male</label>
            <input
              type="radio"
              id="female"
              name="gender"
              value="FEMALE"
              checked={formData.gender === "FEMALE"}
              onChange={handleChange}
            />
            <label htmlFor="female">Female</label>
          </div>
          <button className="login-button" onClick={handleSubmit}>
            SIGN UP
          </button>
        </div>
        <p className="sign-up-navigation">
          Already have an Account? <Link to="/login">Sign In</Link>
        </p>
        <div className="privacy-policy-acceptance">
          <p>
            By signing up & logging in, I agree with Woofily's <Link>Terms of Service</Link> &<Link> Privacy & Policy</Link>
          </p>
        </div>
      </div>
      <div className="content-container-wrapper flex-center">
        <div className="main-img-wrapper">
          <img src="/assets/images/auth/man-with-dog.png" alt="" />
          <div className="paw-overlay flex-center">
            <img src="/assets/images/auth/yellow-paw.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterationStepTwo;
